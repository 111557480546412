<script setup lang="ts">
import type { JobWithLocations, Job, Location } from "@/types";

const { primaryColor } = usePrimaryColor();

const props = defineProps<{
  job: Job | JobWithLocations;
  primaryCity?: string | null;
  primaryState?: string | null;
  loadLocations?: boolean;
}>();

const color = computed(() => primaryColor.value);
const _locations = ref<Location[]>([]);

const locations = computed(() => {
  const locs =
    "locations" in props.job ? props.job.locations : _locations.value;
  const states = maybeArray(props.primaryState);
  return locs.sort((a, b) => {
    if (states.includes(a?.statecode)) return -1;
    if (states.includes(b?.statecode)) return 1;
    return 0;
  });
});

const displayedCityState = computed(() => {
  if (props.primaryCity && props.primaryState)
    return `${props.primaryCity}, ${props.primaryState}`;
  if (props.primaryState) return props.primaryState;

  if (!locations.value.length) return null;

  return `${locations.value.at(0)?.city}, ${locations.value.at(0)?.state}`;
});

onMounted(async () => {
  if (props.loadLocations) {
    try {
      const { locations } = await $fetch<JobWithLocations>(
        `/api/jobs/${props.job.id}`,
        {
          query: {
            include: "locations",
          },
        }
      );
      _locations.value = locations;
    } catch (e) {
      console.log(e);
    }
  }
});
</script>
<template>
  <Transition>
    <h6
      v-if="displayedCityState"
      class="flex items-center text-base font-medium"
    >
      <IconMapPinOutline class="w-4 mr-1" :style="{ color: color }" />
      {{ displayedCityState }}

      <Transition>
        <span v-if="locations.length > 1">
          <VTooltip class="inline">
            <span class="ml-1 text-xs opacity-50"
              >+ {{ (locations.length - 1).toLocaleString() }} more</span
            >

            <template #popper>
              <ul class="py-1 text-xs">
                <li
                  v-for="location in locations.filter((l, i) => i > 0)"
                  :key="location.zip"
                >
                  {{ location.city }}, {{ location.state }}
                </li>
              </ul>
            </template>
          </VTooltip>
        </span>
      </Transition>
    </h6>
  </Transition>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
